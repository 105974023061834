import { useState, createContext, useCallback, useEffect, useContext, useMemo } from 'react';
import { useNavigate } from "react-router-dom";

import axios from 'axios';

import { useAuth } from './AuthProvider';
import { useTheme } from './ThemeProvider';

import ProgressService from '../services/progress.service';
import AssetsService from '../services/assets.service';
import DebugService from '../services/debug.service';
import AwardsService from '../services/awards.service';
import AvatarsService from '../services/avatars.service';
import TeamsService from '../services/teams.service';
import ProfilesService from '../services/profiles.service';
import PersonalitiesService from '../services/personalities.service';
import JobsService from '../services/jobs.service';
import PlacesService from '../services/places.service';
import CursusService from '../services/cursus.service';

export const GameContext = createContext();

export const useGame = () => {
  return useContext(GameContext);
};

export const GameProvider = ({ children }) => {

  const { isUserRestoredFromApi } = useAuth();
  const { animateButtonBook, animateButtonVideos, animateButtonAwards, isAwardModalOpen } = useTheme();

  // LOAD.CONFIG /////////////////////////////////////
  const [config, setConfig] = useState({});
  const [confIsLoaded, setConfIsLoaded] = useState(false);
  const [configPersonalitiesIsLoaded, setConfigPersonalitiesIsLoaded] = useState(false);
  const [configJobsIsLoaded, setConfigJobsIsLoaded] = useState(false);
  const [configCursusIsLoaded, setConfigCursusIsLoaded] = useState(false);
  const [configPlacesIsLoaded, setConfigPlacesIsLoaded] = useState(false);


  // PAGE.CHANGE /////////////////////////////////////
  const navigate = useNavigate();

  // All states
  const [isGameRestoredFromApi, setIsGameRestoredFromApi] = useState(false);

  const [appDebugMode, setAppDebugMode] = useState(DebugService.isDebug());

  const [progression, setProgression] = useState(0);
  const [maxProgression, setMaxProgression] = useState(ProgressService.get('maxProgression'));

  const [needsCurrentSessionReload, setNeedsCurrentSessionReload] = useState(false);

  const [volume, setVolume] = useState(ProgressService.get('volume'));

  const [userInfos, setUserInfos] = useState(ProgressService.get('infos'));

  const [avatarId, setAvatarId] = useState(ProgressService.get('avatar'));
  const [avatar, setAvatar] = useState({ id: null, src: null });

  const [teamId, setTeamId] = useState(ProgressService.get('team'));
  const [team, setTeam] = useState({ id: null, src: null });

  const [connaissancesScore, setConnaissancesScore] = useState(ProgressService.get('connaissances'));

  const [awards, setAwards] = useState(ProgressService.get('awards'));
  const [isAwardsDisabled, setIsAwardsDisabled] = useState(true);
  const [awardFullscreenOnNextRoute, setAwardFullscreenOnNextRoute] = useState(false);
  const [awardFullscreen, setAwardFullscreen] = useState(false);

  const [isVideosDisabled, setIsVideosDisabled] = useState(true);
  const [videos, setVideos] = useState(ProgressService.get('videos'));

  const [isBookDisabled, setIsBookDisabled] = useState(true);
  const allowedBookKeys = useMemo(() => ['infos', 'team', 'avatar', 'awards', 'axes', 'job', 'withCursus', 'competencesRating', 'cursusLikes'], []);
  const emptyBook = useMemo(() => ({
    infos: null,
    team: null,
    avatar: null,
    awards: null,
    axes: null,
    job: null,
    withCursus: false,
    competencesRating: null,
    cursusLikes: null,
  }), []);
  const [book, setBook] = useState(null);
  useEffect(() => {
    setBook(prevBook => prevBook || emptyBook);
  }, [emptyBook, setBook]);

  const [axesQuestions, setAxesQuestions] = useState([]);
  const [userAxes, setUserAxes] = useState(ProgressService.get('axes'));

  const [jobsQuestions, setJobsQuestions] = useState({});
  const [userJobs, setUserJobs] = useState(ProgressService.get('jobs'));
  const [userJobFavorite, setUserJobFavorite] = useState(ProgressService.get('jobFavorite'));

  const [coachingCompetencesRating, setCoachingCompetencesRating] = useState(ProgressService.get('competencesRating'));

  const [cursusLikes, setCursusLikes] = useState(ProgressService.get('cursusLikes'));


  // AFTER SIGNIN /////////////////////////////////////
  useEffect(() => {
    if (isUserRestoredFromApi) {
      setMaxProgression(ProgressService.get('maxProgression'));
      setVolume(ProgressService.get('volume'));
      setUserInfos(ProgressService.get('infos'));
      setAvatarId(ProgressService.get('avatar'));
      setTeamId(ProgressService.get('team'));
      setConnaissancesScore(ProgressService.get('connaissances'));
      setAwards(ProgressService.get('awards'));
      setVideos(ProgressService.get('videos'));
      setUserAxes(ProgressService.get('axes'));
      setUserJobs(ProgressService.get('jobs'));
      setUserJobFavorite(ProgressService.get('jobFavorite'));
      setCoachingCompetencesRating(ProgressService.get('competencesRating'));
      setCursusLikes(ProgressService.get('cursusLikes'));
      setIsGameRestoredFromApi(true);
    }
  }, [isUserRestoredFromApi, setIsGameRestoredFromApi]);


  // DEBUG /////////////////////////////////////
  const enableDebugMode = useCallback((enable) => {
    setAppDebugMode(enable);
  }, [setAppDebugMode]);
  useEffect(() => {
    DebugService.setDebug(appDebugMode);
    console.log('debug mode set to ', DebugService.isDebug());
  }, [appDebugMode]);


  // CONFIG /////////////////////////////////////
  useEffect(() => {
    axios.get(AssetsService.getUrl('config.json')).then((json) => {
      setConfig(json.data);
    });
  }, []);
  useEffect(() => {
    setConfIsLoaded(!!config && Object.keys(config).length);
  }, [config, setConfIsLoaded]);
  useEffect(() => {
    if (config && config.personalities) {
      PersonalitiesService.populateFromConfig(config);
      setConfigPersonalitiesIsLoaded(true);
    }
  }, [config, setConfigPersonalitiesIsLoaded]);
  useEffect(() => {
    if (config && config.jobs) {
      JobsService.populateFromConfig(config);
      setConfigJobsIsLoaded(true);
    }
  }, [config, setConfigJobsIsLoaded]);
  useEffect(() => {
    if (config && config.cursus) {
      CursusService.populateFromConfig(config);
      setConfigCursusIsLoaded(true);
    }
  }, [config, setConfigCursusIsLoaded]);
  useEffect(() => {
    if (config && config.places) {
      PlacesService.populateFromConfig(config);
      setConfigPlacesIsLoaded(true);
    }
  }, [config, setConfigPlacesIsLoaded]);


  // PROGRESSION /////////////////////////////////////
  const updateProgression = useCallback((progression) => {
    let newProgression = Math.round(progression * 100) / 100;
    setProgression(newProgression);
    if (newProgression > maxProgression) {
      setMaxProgression(newProgression);
    }
  }, [maxProgression, setMaxProgression]);


  // NAVIGATION /////////////////////////////////////
  // Pages and navigation functions

  const checkIfAwardNeedsToBeShown = useCallback(() => {
    if (awardFullscreenOnNextRoute && AwardsService.getAward(awardFullscreenOnNextRoute)) {
      setAwardFullscreen(awardFullscreenOnNextRoute);
      setAwardFullscreenOnNextRoute(false);
    }
  }, [awardFullscreenOnNextRoute, setAwardFullscreenOnNextRoute, setAwardFullscreen]);

  const routeTo = useCallback((path, options) => {
    checkIfAwardNeedsToBeShown();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    navigate(path, options);
  }, [navigate, checkIfAwardNeedsToBeShown]);

  useEffect(() => {
    if (isAwardModalOpen) {
      setAwardFullscreenOnNextRoute(prevAwardFullscreen => prevAwardFullscreen ? null : prevAwardFullscreen);
    }
  }, [isAwardModalOpen, setAwardFullscreenOnNextRoute]);


  // USER INFOS /////////////////////////////////////
  const changeUserInfos = useCallback((field, value) => {
    setUserInfos(prevUserInfos => ({
      ...prevUserInfos,
      [field]: value,
    }));
  }, [setUserInfos]);


  // AVATAR /////////////////////////////////////
  const changeAvatar = useCallback(avatar => {
    appDebugMode && console.log('change avatar', typeof avatar.id !== 'undefined' ? avatar.id : (avatar ? parseInt(avatar) : false));
    setAvatarId(typeof avatar.id !== 'undefined' ? avatar.id : (avatar ? parseInt(avatar) : false));
  }, [appDebugMode, setAvatarId]);
  const resetAvatar = useCallback(() => {
    appDebugMode && console.log('reset avatar');
    changeAvatar(false);
  }, [appDebugMode, changeAvatar]);
  useEffect(() => {
    setAvatar(prevAvatar => !prevAvatar || (prevAvatar && prevAvatar.id !== avatarId) ? (avatarId ? AvatarsService.getAvatar(avatarId) : { id: null, src: null }) : prevAvatar);
  }, [avatarId, setAvatar]);


  // TEAM /////////////////////////////////////
  const changeTeam = useCallback(team => {
    appDebugMode && console.log('change team', typeof team.id !== 'undefined' ? team.id : (team ? team : false));
    setTeamId(typeof team.id !== 'undefined' ? team.id : (team ? team : false));
  }, [appDebugMode, setTeamId]);
  const resetTeam = useCallback(() => {
    appDebugMode && console.log('reset team');
    changeTeam(false);
  }, [appDebugMode, changeTeam]);
  useEffect(() => {
    setTeam(prevTeam => !prevTeam || (prevTeam && prevTeam.id !== teamId) ? (teamId ? TeamsService.getTeam(teamId) : { id: null, src: null }) : prevTeam);
  }, [teamId, setTeam]);


  // SOUNDS /////////////////////////////////////
  const changeVolume = useCallback((value) => {
    setVolume(parseFloat(value));
    ProgressService.set('volume', parseFloat(value));
  }, [setVolume]);
  const resetVolume = useCallback(() => {
    appDebugMode && console.log('reset volume to 0.2');
    changeVolume(0.2);
  }, [appDebugMode, changeVolume]);


  // AWARDS /////////////////////////////////////
  const addAward = useCallback((awardId, showFullscreenAfter = false, showImmediatly = false) => {
    // console.log('adding award ', awardId, ' ? exists ', AwardsService.getAwardsIds().includes(awardId), 'already', awards.includes(awardId));
    if (AwardsService.getAwardsIds().includes(awardId)) {
      setAwards(prevAwards => {
        let newAwards = prevAwards;
        if (!newAwards.includes(awardId)) {
          newAwards = Array.from(newAwards);
          newAwards.push(awardId);
          // console.log('added award', awardId, newAwards);
          if (showImmediatly) {
            setAwardFullscreen(awardId);
          } else if (showFullscreenAfter) {
            setAwardFullscreenOnNextRoute(awardId);
          }
        }
        // always include accueil award
        /* if (!newAwards.includes('accueil')) {
          newAwards = Array.from(newAwards);
          newAwards.push('accueil');
        }*/
        return newAwards;
      });
    }
  }, [setAwards, setAwardFullscreen, setAwardFullscreenOnNextRoute]);
  const removeAward = useCallback((awardId) => {
    setAwards(prevAwards => {
      let newAwards = prevAwards;
      if (prevAwards.includes(awardId)) {
        newAwards = Array.from(prevAwards.filter(award => award !== awardId));
      }
      return newAwards;
    });
  }, [setAwards]);
  const removeAwards = useCallback((awardsIds) => {
    setAwards(prevAwards => prevAwards.filter(award => !awardsIds.includes(award)));
  }, [setAwards]);
  const resetAwards = useCallback(() => {
    appDebugMode && console.log('reset awards', awards, AwardsService.getAwardsIds());
    removeAwards(AwardsService.getAwardsIds());
  }, [appDebugMode, awards, removeAwards]);
  // auto update isAwardsDisabled
  useEffect(() => {
    setIsAwardsDisabled(prevDisabled => {
      if (prevDisabled && awards.length) {
        return false;
      } else if (!prevDisabled && !awards.length) {
        return true;
      }
      return prevDisabled;
    });
    ProgressService.set('awards', awards);
  }, [awards, setIsAwardsDisabled]);
  useEffect(() => {
    !isAwardsDisabled && animateButtonAwards(2000);
  }, [isAwardsDisabled, animateButtonAwards]);


  // VIDEOS /////////////////////////////////////
  const addVideo = useCallback((videoId) => {
    setVideos(prevVideos => {
      let newVideos = prevVideos;
      if (!newVideos.includes(videoId)) {
        newVideos = Array.from(newVideos);
        newVideos.push(videoId);
        appDebugMode && console.log('added video', videoId, newVideos);
      }
      return newVideos;
    });
    if (!videos.includes(videoId)) {
      animateButtonVideos();
    }
  }, [appDebugMode, videos, setVideos, animateButtonVideos]);
  const addVideos = useCallback((videosIds) => {
    setVideos(prevVideos => {
      let newVideos = prevVideos;
      videosIds.forEach(videoId => {
        if (!newVideos.includes(videoId)) {
          newVideos = Array.from(newVideos);
          newVideos.push(videoId);
        }
      });
      if (prevVideos.length !== newVideos.length) {
        appDebugMode && console.log('added video', videosIds, newVideos);
        animateButtonVideos();
      }
      return newVideos;
    });
    animateButtonVideos();
  }, [appDebugMode, setVideos, animateButtonVideos]);
  const resetVideos = useCallback(() => {
    appDebugMode && console.log('reset videos', videos);
    setVideos([]);
  }, [appDebugMode, videos, setVideos]);
  // auto update isVideosDisabled
  useEffect(() => {
    setIsVideosDisabled(prevDisabled => {
      if (prevDisabled && videos.length) {
        return false;
      } else if (!prevDisabled && !videos.length) {
        return true;
      }
      return prevDisabled;
    });
    ProgressService.set('videos', videos);
  }, [videos, setIsVideosDisabled]);
  useEffect(() => {
    !isVideosDisabled && animateButtonVideos(2000);
  }, [isVideosDisabled, animateButtonVideos]);


  // BOOK /////////////////////////////////////
  const addToBook = useCallback((key, data) => {
    appDebugMode && console.log('add to book', key, data);
    if (allowedBookKeys.includes(key)) {
      setBook(prevBook => (!prevBook ? {
        ...emptyBook,
        [key]: data
      } : {
        ...prevBook,
        [key]: data
      }));
      animateButtonBook();
    }
  }, [appDebugMode, allowedBookKeys, emptyBook, setBook, animateButtonBook]);
  const mergeWithBook = useCallback((data) => {
    appDebugMode && console.log('merge into book', data);
    setBook(prevBook => (!prevBook ? {
      ...emptyBook,
      ...data
    } : {
      ...prevBook,
      ...data,
    }));
    animateButtonBook();
  }, [appDebugMode, emptyBook, setBook, animateButtonBook]);
  const resetBookPart = useCallback((key) => {
    appDebugMode && console.log('reset book part', key);
    if (allowedBookKeys.includes(key)) {
      setBook(prevBook => (!prevBook ? {
        ...emptyBook,
        [key]: null
      } : {
        ...prevBook,
        [key]: null
      }));
    }
  }, [appDebugMode, allowedBookKeys, emptyBook, setBook]);
  const resetBook = useCallback(() => {
    appDebugMode && console.log('reset book');
    setBook(emptyBook);
  }, [appDebugMode, emptyBook, setBook]);
  const resetBookWithData = useCallback((data) => {
    appDebugMode && console.log('reset book with data', data);
    setBook({
      ...emptyBook,
      ...data,
    });
  }, [appDebugMode, emptyBook, setBook]);
  // auto update isBookDisabled
  useEffect(() => {
    setIsBookDisabled(prevDisabled => {
      const bookIsEmpty = !book || allowedBookKeys.reduce((isEmpty, bookKey) => isEmpty && (!book[bookKey] || book[bookKey] === null), true);
      if (prevDisabled && !bookIsEmpty) {
        return false;
      } else if (!prevDisabled && bookIsEmpty) {
        return true;
      }
      return prevDisabled;
    });
  }, [allowedBookKeys, book, setIsBookDisabled]);
  useEffect(() => {
    !isBookDisabled && animateButtonBook(2000);
  }, [isBookDisabled, animateButtonBook]);


  // @todo: remove
  function addBook(id) {
    let _book = [...book];
    _book.push(id);
    setBook(_book)
    setIsBookDisabled(false)
  }


  // CONNAISSANCES /////////////////////////////////////
  const resetConnaissancesScore = useCallback(() => {
    setConnaissancesScore(0);
  }, [setConnaissancesScore]);


  // PERSONALITY /////////////////////////////////////
  const setUserAxesFromPersonalities = useCallback((personalitiesIds) => {
    const axes = {};
    if (configPersonalitiesIsLoaded) {
      // Add personalities + qualities to user's axe, and compute compatibility score
      personalitiesIds.forEach(personalityId => {
        const axeId = personalityId.slice(0, 2);
        axes[axeId] = axes[axeId] || { id: axeId, score: 0, personalities: [], qualities: [] };
        axes[axeId].personalities.push(personalityId);
        axes[axeId].score = axes[axeId].personalities.length / PersonalitiesService.perProfileCount;
        PersonalitiesService.getPersonalities().forEach(personality => {
          if (personality.id === personalityId) {
            axes[axeId].qualities = axes[axeId].qualities.concat(personality.qualities.filter((value) => !axes[axeId].qualities.includes(value)));
          }
        });
      });
      // Add default axe values
      ProfilesService.getProfiles().forEach(profile => {
        axes[profile.id] = axes[profile.id] || { id: profile.id, score: 0, personalities: [], qualities: [] };
      });
    }
    setUserAxes(axes);
  }, [configPersonalitiesIsLoaded, setUserAxes]);


  // Advanced reset /////////////////////////////////////
  const resetDebriefing = useCallback(() => {
    // awards
    removeAward('communication');
    changeUserInfos('wantsContact', null);
    // @todo: reset debriefing inner progress
  }, [removeAward, changeUserInfos]);

  const resetCoaching = useCallback(() => {
    setCoachingCompetencesRating({});
    setCursusLikes([]);
    // @todo: reset coaching inner progress
    // next steps
    resetDebriefing();
  }, [resetDebriefing, setCoachingCompetencesRating, setCursusLikes]);

  const resetReperages = useCallback(() => {
    // awards
    removeAwards(['solutions', 'responsabilites']);
    if (configJobsIsLoaded && userAxes) {
      setUserJobs(JobsService.compute(userAxes));
    }
    setJobsQuestions({});
    // next steps
    resetCoaching();
  }, [configJobsIsLoaded, userAxes, resetCoaching, setUserJobs, removeAwards]);

  const resetAuditions = useCallback(() => {
    // awards
    removeAward('rigueur');
    // progress
    setUserAxes({});
    setUserJobs({});
    // next steps
    resetReperages();
  }, [resetReperages, removeAward, setUserAxes, setUserJobs]);

  const resetBrief = useCallback(() => {
    resetTeam();
    resetConnaissancesScore();
    resetAwards();
    resetVideos();
    // @todo? reset user infos 
    // @todo? reset avatar resetAvatar();
    // reset book
    resetBook();
    // next steps
    resetAuditions();
  }, [resetTeam, resetAwards, resetVideos, resetConnaissancesScore, resetBook, resetAuditions]);

  const resetAccueil = useCallback(() => {
    resetVolume();
    // next steps
    resetBrief();
  }, [resetVolume, resetBrief]);


  return (
    <GameContext.Provider value={{
      isGameRestoredFromApi,
      // debug
      appDebugMode,
      enableDebugMode,
      // config
      confIsLoaded,
      config,
      configPersonalitiesIsLoaded,
      configJobsIsLoaded,
      configCursusIsLoaded,
      configPlacesIsLoaded,
      // navigation
      routeTo,
      needsCurrentSessionReload,
      setNeedsCurrentSessionReload,
      // progression
      progression,
      maxProgression,
      updateProgression,
      // volume
      volume,
      changeVolume,
      resetVolume,
      // awards
      awards,
      setAwards,
      addAward,
      removeAward,
      removeAwards,
      resetAwards,
      isAwardsDisabled,
      awardFullscreen,
      setAwardFullscreen,
      awardFullscreenOnNextRoute,
      setAwardFullscreenOnNextRoute,
      checkIfAwardNeedsToBeShown,
      // Videos
      videos,
      addVideo,
      addVideos,
      resetVideos,
      isVideosDisabled,
      // Book
      book,
      addToBook,
      mergeWithBook,
      resetBookPart,
      resetBook,
      resetBookWithData,
      isBookDisabled,
      addBook,
      // Avatar
      avatarId,
      setAvatarId,
      avatar,
      changeAvatar,
      resetAvatar,
      // Team
      teamId,
      setTeamId,
      team,
      changeTeam,
      resetTeam,
      // User infos
      userInfos,
      changeUserInfos,
      // Acte 01 connaissances
      connaissancesScore,
      setConnaissancesScore,
      resetConnaissancesScore,
      // Auditions
      axesQuestions,
      setAxesQuestions,
      userAxes,
      setUserAxes,
      setUserAxesFromPersonalities,
      // Repérages
      jobsQuestions,
      setJobsQuestions,
      userJobs,
      setUserJobs,
      userJobFavorite,
      setUserJobFavorite,
      // Coaching
      coachingCompetencesRating,
      setCoachingCompetencesRating,
      cursusLikes,
      setCursusLikes,
      // global reset
      resetDebriefing,
      resetCoaching,
      resetReperages,
      resetAuditions,
      resetBrief,
      resetAccueil,
    }}>
      {children}
    </GameContext.Provider>
  )
}