import axios from 'axios';
import ProgressService from './progress.service';

const API_URL = 'https://api.ifcast.ifpass.fr/api/';
const tokenKey = 'loginToken';

const ApiService = {
  currentToken: localStorage.getItem(tokenKey) || null,
  setCurrentToken: (token) => {
    ApiService.currentToken = token;
    localStorage.setItem(tokenKey, token);
  },
  requestsHeader: (withToken = true) => ({
    // withCredentials: true,
    // mode: 'no-cors',
    ...(withToken ? { 'x-access-token': ApiService.currentToken } : {}),
  }),
  onAPIError: (callback, error) => {
    callback('API error', error);
  },
  logout: ({ onSuccess: successCallback }) => {
    ApiService.currentToken = null;
    localStorage.removeItem(tokenKey);
    successCallback();
  },
  registerNew: (email, { onSuccess: successCallback, onError: errorCallback }) => {
    axios.post(`${API_URL}register`, {
      email,
    }, ApiService.requestsHeader(false))
      .then(({ data: { success, message, data } }) => success ? successCallback(message) : errorCallback(message, data))
      .catch(error => ApiService.onAPIError(errorCallback, error));
  },
  registerExisting: (email, { onSuccess: successCallback, onError: errorCallback }) => {
    axios.post(`${API_URL}connect`, {
      email,
    }, ApiService.requestsHeader(false))
      .then(({ data: { success, message, data } }) => success ? successCallback(message) : errorCallback(message, data))
      .catch(error => ApiService.onAPIError(errorCallback, error));
  },
  login: (token, { onSuccess: successCallback, onError: errorCallback }) => {
    axios.post(`${API_URL}signin`, {
      token,
    }, ApiService.requestsHeader(false))
      .then(({ data: { success, email, message, data } }) => {
        // console.log('login with token ', token, 'data : ', { success, email, message, data });
        ApiService.setCurrentToken(token);
        ProgressService.restoreFromApi({ infos: { ...((data && data.infos) || {}), email }, ...(data || {}) });
        success ? successCallback(message, data) : errorCallback(message, data);
      })
      .catch(error => ApiService.onAPIError(errorCallback, error));
  },
  scorm: (hash, { onSuccess: successCallback, onError: errorCallback }) => {
    axios.post(`${API_URL}scorm`, {
      hash,
    }, ApiService.requestsHeader(false))
      .then(({ data: { token } }) => {
        ApiService.login(token, { onSuccess: successCallback, onError: errorCallback });
      })
      .catch(error => ApiService.onAPIError(errorCallback, error));
  },
  emailBookLink: (email, { onSuccess: successCallback, onError: errorCallback }) => {
    axios.post(`${API_URL}book`, {
      token: ApiService.currentToken,
      email,
    }, ApiService.requestsHeader(true))
      .then(({ status, data: { success, message, data } }) => status === 200 && success ? successCallback(message) : errorCallback(message, data))
      .catch(error => ApiService.onAPIError(errorCallback, error));
  },
  updateData: (data, { onSuccess: successCallback, onError: errorCallback }) => {
    axios.post(`${API_URL}update`, {
      token: ApiService.currentToken,
      results: data,
    }, ApiService.requestsHeader(true))
      .then(({ status, data: { success, message } }) => status === 200 && success ? successCallback(message) : errorCallback(message))
      .catch(error => ApiService.onAPIError(errorCallback, error));
  },
};
export default ApiService;